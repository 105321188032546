import React from "react";
import "./dataform.scss";

import PropTypes from 'prop-types';
import { IMaskInput } from 'react-imask';
import Card from "../formCard/card";
import TextField from '@mui/material/TextField';
import { withTranslation } from 'react-i18next';

const TextMaskCard = React.forwardRef(function TextMaskCard(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask='0000 0000 0000 0000'
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
      unmask={true}
    />
  );
});

TextMaskCard.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const TextMaskExp = React.forwardRef(function TextMaskExp(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask='00 / 00'
      inputRef={ref}
      onAccept={(value) => onChange({
        target: { name: props.name, value: value.replace(/\s/g, '') }
      })}
      overwrite
    />
  );
});

TextMaskExp.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const TextMaskCvv = React.forwardRef(function TextMaskCvv(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask='000'
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

TextMaskCvv.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const dataForm = (props) => {
  const { t } = props;
  
  return (
    <Card styles={props.styles}>
      <div className="header">
        <p>
        <span>{props.quantity} {t('articles')}</span>{t('inCart')}
        </p>
      </div>
      <div className="title">
        <div>
          <img
            className="title-image"
            src={props.styles.checkoutIconData}
            alt="car_icon"
          />
        </div>
        <div>
          <h1
            className="title-name"
            style={{
              color: props.styles.containedPrimarycolor
            }}>{t('formTitle')}</h1>
        </div>
      </div>
      <hr className="blue-bar"
        style={{
          border: `1px solid ${props.styles.containedSecondarycolor}`
        }}/>
      <form id="sendTransaction">
        <TextField
          id="email"
          name="email"
          label={t('emailLabel')}
          type="email"
          error={!props.inputCorrect || props.inputCorrect.email ? false : true }
          value={props.transactionBody && props.transactionBody.email ? props.transactionBody.email : ''}
          onChange={props.onChangeInput}
        />
        <TextField
          id="cardHolderName"
          name="cardHolderName"
          label={t('nameLabel')}
          type="cardHolderName"
          error={!props.inputCorrect || props.inputCorrect.cardHolderName ? false : true }
          value={props.transactionBody && props.transactionBody.cardHolderName ? props.transactionBody.cardHolderName : ''}
          onChange={props.onChangeInput}
        />
        <div className="cardLogos">
          <TextField
            id="cardNumber"
            name="cardNumber"
            label={t('cardLabel')}
            type="cardNumber"
            error={!props.inputCorrect || props.inputCorrect.cardNumber ? false : true}
            value={props.transactionBody && props.transactionBody.cardNumber ? props.transactionBody.cardNumber : ''}
            onChange={props.onChangeInput}
            InputProps={{
              inputComponent: TextMaskCard,
            }}
            />
          <div className="formLogos">
            {(props.cardType === '4') && (
              <img className="visa" src="https://storage.googleapis.com/mailing-images-belugapay/carrito/002_visa.svg" alt="visa" />
            )}
            {(props.cardType === '5') && (
              <img className="master" src="https://storage.googleapis.com/mailing-images-belugapay/carrito/003_masterard.svg" alt="mc" />
            )}
          </div>
        </div>
        <div className="fields">
          <TextField
            id="exp"
            name="exp"
            label={t('dateLabel')}
            type="exp"
            error={!props.inputCorrect || props.inputCorrect.exp ? false : true}
            value={props.transactionBody && props.transactionBody.exp ? props.transactionBody.exp : ''}
            onChange={props.onChangeInput}
            InputProps={{
              inputComponent: TextMaskExp,
            }}
            />
          <TextField
            id="cvv"
            name="cvv"
            className="input-cvv"
            label={t('cvvLabel')}
            type="cvv"
            error={!props.inputCorrect || props.inputCorrect.cvv ? false : true}
            value={props.transactionBody && props.transactionBody.cvv ? props.transactionBody.cvv : ''}
            onChange={props.onChangeInput}
            InputProps={{
              inputComponent: TextMaskCvv,
            }}
            />
        </div>
      </form>
    </Card>
  );
};

export default withTranslation()(dataForm);
