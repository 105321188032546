import { Component } from "react";
import { connect } from "react-redux";
import ReactToPrint from "react-to-print";
import Button from '@mui/material/Button';
import { withTranslation } from 'react-i18next';
import "./checkoutPaymentSpei.scss";
import React from "react";
import Snackbar from '@mui/material/Snackbar';

class ComponentToPrint extends Component {
  state = {
    open: false,
    strQR: ''
  };

  componentDidMount() {
    this.props.codi && this.getSQ();
  }

  async getSQ() {
    const res = await fetch(
      `${global.config.TRANSFER.BASE}/api/codi/${this.props.commerceDetails.clabe.id}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          conceptoPago: this.props.reference,
          monto: this.props.amount,
          tipoPago: "20",
          minutosLimite: "500"
        }),
      }
    );
    const data = await res.json();
    this.setState({
      strQR: data.payload.qrImage
    });
  }

  getExpiration() {
    const date = new Date();
    date.setDate(date.getDate() + 5);

    const dd = date.getDate();
    const mm = date.getMonth() + 1;
    const y = date.getFullYear();

    return `${dd} / ${(mm < 10 ? `0${mm}` : mm) } / ${y}`;
  }

  formatCurrency (number) {
    const numberSplit = Number(number).toFixed(2).toString().split('.');
    const formatNumber = (numberSplit && numberSplit[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','));
    return formatNumber + '.' + ((numberSplit && numberSplit[1]) ? numberSplit[1] : '00');
  }

  handleClose() {
    this.setState({
      open: false
    });
  };

  render() {
    const { t } = this.props;
    // var Barcode = require('react-barcode');
    let clabe = this.props?.commerceDetails?.clabe?.stpAccountClabe[0]?.clabe?.clabe;
    let codi = this.props?.codi;
    return (
      <div className="print-container">
        <div className="container-referencePay">
          <div className="logo">
            <span>
              {t('ProcessedBy')}
            </span>
            <img src={this.props?.distributorInfo?.imgLogoProcess} alt="Logo" />
          </div>
          {/* <div className="container-title-reference">
            <img className="icon-checkout" src="/images/009_icon_Detalle.svg" alt="" /> {t('detailsPurchase')}
          </div> */}
          <div className="table-data">
          <div className="title">
              {t('commerceName')}
            </div>
            <div className="data-reference">
              {this.props?.commerceDetails?.commerceName}
            </div>
            <div className="line"></div>
            <div className="title monto-weight">
              {t('service')}
            </div>
            <div className="data-reference monto-weight">
              SPEI
            </div>
            <div className="line"></div>
            <div className="title monto-weight" style={{ display: 'flex' }}>
              Clabe
            </div>
            <div className="data-reference monto-weight">
              <Button
                variant="outlined"
                onClick={() => {
                  navigator.clipboard.writeText(clabe);
                  this.setState({
                    open: true
                  });
                }}>
                {`${clabe[0]}${clabe[1]}${clabe[2]} 
                ${clabe[3]}${clabe[4]}${clabe[5]} 
                ${clabe[6]}${clabe[7]}${clabe[8]}${clabe[9]} 
                ${clabe[10]}${clabe[11]}${clabe[12]}${clabe[13]}${clabe[14]}${clabe[15]}${clabe[16]} 
                ${clabe[17]}`}
              </Button>
            </div>
            <div className="line"></div>
            <div className="title monto-weight">
              {t('concept')}
            </div>
            <div className="data-reference monto-weight">
              {t('conceptDetails')}
            </div>
            <div className="line"></div>
            <div className="title monto-weight">
              {t('amount')}
            </div>
            <div className="data-reference monto-weight">
              MXN $ {this.formatCurrency(this.props.amount)}
            </div>
            {/* <div className="line"></div> */}
            {/* <div className="title">
              {t('effectiveUntil')}
            </div>
            <div className="data-reference">
              {this.getExpiration()}
            </div> */}
          </div>
          <div className="purchase-details">
            {/* <div>
              {t('purchaseDetails')}
            </div> */}
            <div className="items-list">
              {this.props?.items && this.props.items.map((value, index) => {
                return (
                  <span key={index}>
                    <div>
                      {value.name} <span className="quantity">x{value.quantity}</span> 
                    </div>
                    <div>
                      $ {this.formatCurrency(value.price)}
                    </div>
                    <div>
                      {value.description && (
                        <span>{value.description}</span>
                      )}
                    </div>
                  </span>
                )
              })}
            </div>
          </div>
          <div className="instructions-details">
            <div className="services-allow">
              <div>
                {t('instructions')}
              </div>
              <div className="instructions-steps">
                <ol>
                  <li>
                    {t('PresentCashierSpei')}
                  </li>
                  <li>
                    {t('provideClabe')}
                  </li>
                  <li>
                    {t('ProvideConcept')}
                  </li>
                  <li>
                    {t('RequestProofPayment')}
                  </li>
                </ol>
              </div>
            </div>
          </div>
          {/* {
            clabe && (
              <div className="barcode">
                <Barcode ref={this.BarcodeRef} value={clabe} />
              </div>
          )} */}
          {
            codi && this.state.strQR !== '' && (
              <div style={{textAlign: 'center'}}>
                <br/>
                <div>
                  CODI
                </div>
                <img src={this.state.strQR} alt="QR" width={'300px'} />
              </div>
            )
          }
        </div>
        <Snackbar
          className="copyAlert"
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          autoHideDuration={3000}
          open={this.state.open}
          onClose={() => this.handleClose()}
          message="Copiado!"
          key={`'top center`}
        />
      </div>
    );
  }
}

class checkoutPaymentReference extends Component {

  state = {
    reference: ''
  };

  componentDidMount() {
  }

  render() {
    const { t } = this.props;
    return (
      <div className="checkoutPaymentSpei">
        <ComponentToPrint
          ref={(el) => (this.componentRef = el)}
          reference={this.props.payOrder.transactionIdentifier.reference}
          amount={this.props.payOrder.transactionInfo.price3dSecureMXN}
          items={this.props.payOrder.payOrder.transaction.items}
          distributorInfo={this.props.distributorInfo}
          commerceDetails={this.props.payOrder.commerceDetails}
          codi={this.props.payOrder.transactionInfo.codiPaymentEnable}          
          t={t} />

        <div className="text-center">
          <ReactToPrint
            trigger={() => 
              <Button
                variant="contained"
                color="primary"
                className="large paymentbtn">{t('print')}</Button>}
            content={() => this.componentRef}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    payOrder: state.payOrder
  };
}

export default connect(mapStateToProps)(withTranslation()(checkoutPaymentReference));
