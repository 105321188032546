import React, { Component } from "react";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";

import Backdrop from '@mui/material/Backdrop';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

// import { getCardInfo } from '../../../../func/cardInfo';
import CheckoutResponse from '../checkoutResponse/checkoutResponse';

import DataForm from "../../forms/dataFormV2/dataForm";
import DetailCard from "../../../purchDetailCard/purchaseDetailCard";
import ItemList from "../../../listItem/listItem";
import EndPayment from "../../../endPaymentCard/endPayment";

import "./checkout3dSecure2.0Banorte.scss";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  padding: '40px'
};

class Checkout3dSecure extends Component {

  state = {
    transactionBody: {},
    lastStep: false,
    cardinalComplete: false,
    messageError: null,
    modalOpen: false,
    transactionResponse: null
  }

  prepareBodyRequest() {
    const exp = this.state.transactionBody.exp.split('/');
    const country = this.state.transactionBody.country.split('-');
    let state = ['', '', ''];
    let locality = ['', '', ''];
    if (country[1] === 'MX') {
      state = this.state.transactionBody.state.split('-');
      locality = this.state.transactionBody.locality.split('-');
    } else {
      state[1] = this.state.transactionBody.state;
      locality[2] = this.state.transactionBody.locality;
    }
    return {
      orderInformation: {
        amountDetails: {
          currency: this.props.payOrder.payOrder.transaction.currency,
          totalAmount: this.props.payOrder.payOrder.transaction.total
        },
        lineItems: this.props.payOrder.payOrder.transaction.items.map(item => ({
          totalAmount: Number(item.quantity) * Number(item.price),
          quantity: item.quantity,
          unitPrice: item.price,
          productName: item.name,
          productDescription: item.description,
          giftCardCurrency: this.props.payOrder.payOrder.transaction.currency
        }))
      },
      billTo: {
        address1: this.state.transactionBody.address1,
        administrativeArea: state[1],
        country: country[1],
        locality: locality[2],
        firstName: this.state.transactionBody.name,
        lastName: this.state.transactionBody.lastName,
        phoneNumber: this.state.transactionBody.phoneNumber,
        email: this.state.transactionBody.email,
        postalCode: this.state.transactionBody.postalCode,
      },
      paymentInformation: {
        card: {
          expirationMonth: exp[0],
          expirationYear: `20${exp[1]}`,
          number: this.state.transactionBody.cardNumber
        }
      },
      consumerAuthenticationInformation: {
        reference: this.props.payOrder.transactionIdentifier.reference
      }
    }
  }

  prepareSaleBodyRequest() {
    const exp = this.state.transactionBody.exp.split('/');
    const country = this.state.transactionBody.country.split('-');
    let state = ['', '', ''];
    let locality = ['', '', ''];
    if (country[1] === 'MX') {
      state = this.state.transactionBody.state.split('-');
      locality = this.state.transactionBody.locality.split('-');
    } else {
      state[1] = this.state.transactionBody.state;
      locality[2] = this.state.transactionBody.locality;
    }
    return {
      card: {
        number: this.state.transactionBody.cardNumber,
        expirationMonth: exp[0],
        expirationYear: `20${exp[1]}`,
        securityCode: this.state.transactionBody.cvv
      },
      cardHolder: {
        firstName: this.state.transactionBody.name,
        lastName: this.state.transactionBody.lastName,
        email: this.state.transactionBody.email,
        phone: this.state.transactionBody.phoneNumber,
      },
      address: {
        country: country[1],
        state: state[1],
        city: locality[2],
        zipCode: this.state.transactionBody.postalCode,
        street: this.state.transactionBody.address1
      },
      linkDetails: this.props.payOrder.linkDetails,
      transaction: {
        items: this.props.payOrder.payOrder.transaction.items.map(item => ({
          totalAmount: Number(item.quantity) * Number(item.price),
          quantity: item.quantity,
          unitPrice: item.price,
          productName: item.name,
          productDescription: item.description,
          giftCardCurrency: this.props.payOrder.payOrder.transaction.currency
        })),
        total: this.props.payOrder.payOrder.transaction.total,
        currency: this.props.payOrder.payOrder.transaction.currency,
      }
    }
  }

  enrollmet() {
    this.setState({
      cardinalComplete: false
    });
    this.saveOrder()
      .then((resultOrder) => {
        console.log(resultOrder);
        return this.doTransaction();
      })
  }

  saveOrder() {
    return new Promise((resolve, reject) => {
      console.log(this.state, this.props);
      const bodyTransaction = {
        transaction: {
          cardNumber: this.state.transactionBody.cardNumber,
          email: this.state.transactionBody.email,
          cvv: this.state.transactionBody.cvv,
          exp: this.state.transactionBody.exp,
          cardHolderName: this.state.transactionBody.name,
          lastName: this.state.transactionBody.lastName,
          currency: this.props.payOrder.payOrder.transaction.currency
        },
        address: {
          country: this.state.transactionBody.country.split('-')[0],
          state: this.state.transactionBody.state.split('-')[3],
          city: this.state.transactionBody.locality.split('-')[2],
          street: this.state.transactionBody.address1,
          zipCode: this.state.transactionBody.postalCode
        },
        transactionIdentifier: {
          reference: this.props.payOrder.transactionIdentifier.reference
        },
        deferredPayments: {
          paymentsNumber: this.state.transactionBody.months,
          planType: '03' // MSI
          // planType: '05' // MCI
        }
      };
  
      const url = `${global.config.URL.BASE}/cart/order/${this.props.payOrder.transactionIdentifier.jwtOrder}`;
  
      const header = new Headers();
      header.append('Content-Type', 'application/json');
  
      const req = new Request(url, {
        method: 'POST',
        body: JSON.stringify(bodyTransaction),
        headers: header
      });
  
      return fetch(req)
        .then(res => {
          if (res.status !== 200) throw 'Error'
          return res.json()
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  doTransaction() {
    let brand = '';
    if (this.state?.transactionBody?.cardInfo?.brand) {
      brand = this.state.transactionBody.cardInfo.brand === 'MASTERCARD'? 'MC' : 'VISA';
    } else {
      if (this.state.transactionBody.cardNumber[0] === '5') {
        brand = 'MC';
      } else {
        brand = 'VISA';
      }
    }

    let nature = '';
    if (this.state?.transactionBody?.cardInfo?.nature) {
      nature = this.state.transactionBody.cardInfo.nature === 'DÉBITO'? 'DB' : 'CR';
    } else {
      nature = 'CR';
    }
    const country = this.state.transactionBody.country.split('-');
    let state = ['', '', '', ''];
    let locality = ['', '', '', ''];
    if (country[1] === 'MX' || country[1] === 'US' || country[1] === 'CA') {
      state = this.state.transactionBody.state.split('-');
      locality = this.state.transactionBody.locality.split('-');
      console.log(this.state.transactionBody.state.split('-'));
    } else {
      state[3] = this.state.transactionBody.state;
      locality[2] = this.state.transactionBody.locality;
    }

    const form = this.createForm('https://via.banorte.com/secure3d/Solucion3DSecure.htm');
    form.appendChild(this.createInput('MARCA_TARJETA', brand));
    form.appendChild(this.createInput('NUMERO_TARJETA', this.state.transactionBody.cardNumber));
    form.appendChild(this.createInput('FECHA_EXP', this.state.transactionBody.exp));
    form.appendChild(this.createInput('MONTO', this.props.payOrder.transactionInfo.price3dSecureMXN));
    form.appendChild(this.createInput('CIUDAD', locality[2]));
    form.appendChild(this.createInput('PAIS',  country[1]));
    form.appendChild(this.createInput('CORREO', this.state.transactionBody.email));
    form.appendChild(this.createInput('NOMBRE', this.state.transactionBody.name));
    form.appendChild(this.createInput('APELLIDO', this.state.transactionBody.lastName));
    form.appendChild(this.createInput('CODIGO_POSTAL', this.state.transactionBody.postalCode));
    form.appendChild(this.createInput('ESTADO', state[3]));
    form.appendChild(this.createInput('CALLE', this.state.transactionBody.address1));
    form.appendChild(this.createInput('NUMERO_CELULAR', this.state.transactionBody.phoneNumber));
    form.appendChild(this.createInput('TIPO_TARJETA', nature));

    form.appendChild(this.createInput('ID_AFILIACION', this.props.payOrder.commerceDetails.affiliationBan));
    form.appendChild(this.createInput('NOMBRE_COMERCIO', 'ESPIRAL'));
    form.appendChild(this.createInput('CIUDAD_COMERCIO', 'GUADALAJARA'));
    form.appendChild(this.createInput(
      'URL_RESPUESTA',
      `${global.config.URL.BASE}/cart/checkout3D20/sale/${this.props.payOrder.transactionIdentifier.reference}`));
    form.appendChild(this.createInput('CERTIFICACION_3D', '03'));
    form.appendChild(this.createInput('REFERENCIA3D', this.props.payOrder.transactionIdentifier.reference));
    form.appendChild(this.createInput('VERSION_3D', '2'));

    document.body.appendChild(form);

    document.getElementById("sendTransaction3d").submit();
    document.getElementById("sendTransaction3d").remove();
  }

  createForm(url) {
    const form = document.createElement("form");
    form.style.display = "none";
    form.method = 'post';
    form.id = 'sendTransaction3d';
    form.action = url;
    return form;
  }

  createInput(name, value) {
    const input = document.createElement('input');
    input.type = 'text';
    input.name = name;
    input.value = value;
    return input;
  }

  handleChangeInput(event) {
    const inputName = event.target.name;
    const value = event.target.value;
    if (inputName === 'cardNumber') {
      this.setState({
        transactionBody: {
          ...this.state.transactionBody,
          [inputName]: value
        },
        cardType: value[0]
      });
    } else if (inputName === 'country' && inputName === 'state' && inputName === 'locality') {
      this.setState({
        transactionBody: {
          ...this.state.transactionBody,
          [inputName]: value
        }
      });
    } else {
      this.setState({
        transactionBody: {
          ...this.state.transactionBody,
          [inputName]: value
        }
      });
    }
  }

  handleContinueBtn = () => {
    // getCardInfo(this.state.transactionBody.cardNumber.substring(0,8))
    //   .then(result => {
    //     console.log(result);
        this.setState({
          cardinalComplete: true,
          lastStep: true,
          transactionBody: {
            ...this.state.transactionBody,
            cardInfo: {}
          }
        });
      // });
  };

  handleModifyPayment = () => {
    this.setState({
      lastStep: false,
      cardinalComplete: false,
      messageError: null
    });
  }

  handleModal(status) {
    this.setState({
      modalOpen: status
    });
  };

  render() {
    const { t } = this.props;
    if (!this.props.payOrder?.payOrder?.transaction?.currency) {
      return ;
    }

    const {
      payOrder,
      transactionInfo
    } = this.props.payOrder;

    if (transactionInfo && !this.state.lastStep) {
      return (
        <div className="checkout-3d-v2">
          <Container maxWidth="lg">
            <div className="Checkout3dSecurev2">
              <div className="container-info-cardholder">
                <DataForm 
                  quantity={payOrder.transaction.items.length}
                  transactionBody={this.state.transactionBody}
                  // inputCorrect={this.state.inputCorrect}
                  // paymentInMonths={this.state.cardInfo}
                  amount={transactionInfo.price3dSecureMXN}
                  onChangeInput={(event) => this.handleChangeInput(event)}
                  // cardType={this.state.cardType}
                  styles={this.props.styles}
                />
              </div>
              <div className="container-info-sale">
                <DetailCard
                  titleImage={
                    this.props.styles.checkoutIconShop
                  }
                  title={t('yourPurchase')}
                  color="blue"
                  currency={payOrder.transaction.currency}
                  total={payOrder.transaction.total}
                  styles={this.props.styles}
                >
                  {payOrder.transaction.items.map((item, index) => {
                    return (
                      <ItemList
                        key={index}
                        name={item.name}
                        quantity={item.quantity}
                        description={item.description}
                        currency={payOrder.transaction.currency}
                        price={item.price}
                        styles={this.props.styles}
                      />
                    );
                  })}
                </DetailCard>
                <div className="butons">
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ backgroundColor: this.props.styles.containedPrimarycolor }}
                    onClick={this.props.handleBack}>{t('cancelBtn')}</Button>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ backgroundColor: this.props.styles.containedPrimarycolor }}
                    onClick={this.handleContinueBtn}>{t('continueBtn')}</Button>
                </div>
              </div>
            </div>
            <center><small>V2.B</small></center>
          </Container>
        </div>
      );
    } else if (transactionInfo && this.state.lastStep) {
      return (
        <div className="checkout-3d-v2">
          <Container maxWidth="lg">
            <div className="Checkout3dSecurev2">
              <EndPayment 
                lastFour={this.state.transactionBody.cardNumber.slice(-4)}
                handleMod={this.handleModifyPayment}
                // cardType={this.state.cardType}
                styles={this.props.styles}
              />
              <div className="container-info-sale">
                <DetailCard
                  titleImage={
                    this.props.styles.checkoutIconShop
                  }
                  title={t('yourPurchase')}
                  color="green"
                  currency={payOrder.transaction.currency}
                  total={payOrder.transaction.total}
                  styles={this.props.styles}
                >
                  {payOrder.transaction.items.map((item, index) => {
                    return (
                      <ItemList
                        key={index}
                        name={item.name}
                        quantity={item.quantity}
                        description={item.description}
                        currency={payOrder.transaction.currency}
                        price={item.price}
                        total={payOrder.transaction.total}
                        styles={this.props.styles}
                      />
                    );
                  })}
                </DetailCard>
                <div className="butons">
                  {this.state.cardinalComplete
                  && !this.state.messageError
                  && this.state.transactionResponse?.espiralData?.idTransactionStatus !== 1
                  && this.state.transactionBody.cardInfo && (
                    <Button
                      variant="contained"
                      color="primary"
                      className="large"
                      onClick={() => this.enrollmet()}
                      style={{ backgroundColor: this.props.styles.containedPrimarycolor }}>{t('pay')}</Button>
                  )}
                  {!this.state.cardinalComplete
                  && !this.state.transactionResponse && (
                    <CircularProgress />
                  )}
                  {this.state.cardinalComplete
                    && this.state.messageError
                    && (
                    <Stack sx={{ width: '100%' }} spacing={2}>
                      <Alert severity="error">{this.state.messageError}</Alert>
                    </Stack>
                  )}
                  {/* {
                    !this.state.transactionBody.cardInfo && (
                    <Stack sx={{ width: '100%' }} spacing={2}>
                      <Alert severity="error">Tarjeta invalida</Alert>
                    </Stack>
                    )
                  } */}
                </div>
              </div>
            </div>
            <center><small>V2.B</small></center>
          </Container>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={this.state.modalOpen}
            onClose={() => this.handleModal(false)}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
            className="modal-container"
          >
            <Box sx={style}>
              <CheckoutResponse
                transactionResponse={this.state.transactionResponse}
                errorTransaction={this.state.errorTransaction}
                total={this.props.payOrder.payOrder.transaction.total}/>
            </Box>
          </Modal>
        </div>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    payOrder: state.payOrder
  };
}

export default connect(mapStateToProps)(withTranslation()(Checkout3dSecure));
