import React from "react";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Routes, Route } from "react-router-dom";

import Header from "./components/MainPage/Header/Header";
import Footer from "./components/MainPage/Footer/Footer";
import CheckoutMain from "./components/checkoutMain/CheckoutMain";

import "./App.scss";
import "./i18n.js";

const App = () => {
  const theme = createTheme({
    status: {
      palette: {
        type: 'light',
        primary: {
          main: '#009de0'
        },
      },
    },
  });
  
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Header/>
        <Routes>
          <Route
            exact
            path="/:token"
            element={<CheckoutMain/>}
          />
        </Routes>
        <Footer/>
      </ThemeProvider>
    </div>
  );
}

export default App;
