import React from 'react';
import "./selectorComponent.scss"

const selectorComponent = (props) => {
    return (
      <div className="selector" onClick={props.click} style={{border: `1px solid #dadcdc`}}>
        <div className="selector-logo">
          <img className="logo" src={props.logo} alt="" />
        </div>
        <h1 className="selector-title">{props.title}</h1>
        <hr></hr>
        <p className="selector-description">{props.description}</p>
        <div className="selector-bank">
          {(props.bank) && (
            <img className="logo" src={props.bank} alt=""/>
          )}
          {(props.bank2) && (
            <img className="logo" src={props.bank2} alt=""/>
          )}
          {(props.bank3) && (
            <img className="logo" src={props.bank3} alt=""/>
          )}
          {(props.plus) && (
            <div className="plus-text">
              <span>MAS...</span>
            </div>
          )}
        </div>
      </div>
    );
};
  
export default selectorComponent;