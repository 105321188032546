import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { useParams } from 'react-router-dom';
import { connect } from "react-redux";
import { payOrderSave as payOrderSaveActions } from '../../state/payOrder/actions';
import "./CheckoutMain.scss";

import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import { withTranslation } from 'react-i18next';

import checkoutInfo from '../../utils/checkoutInfo';
import ErrorComponent from '../MainPage/Errors/Errors';
import SelectorComponent from "../selectorComponent/selectorComponent";

import CheckoutWithOuth3dSecure from './checkouts/checkoutWithOuth3dSecure/checkoutWithOuth3dSecure';
import Checkout3dSecureV2 from './checkouts/checkout3dSecure2.0/checkout3dSecure2.0';
import Checkout3dSecureV2Banorte from './checkouts/checkout3dSecure2.0Banorte/checkout3dSecure2.0Banorte';
import CheckoutPaymentReference from './checkouts/checkoutPaymentReference/checkoutPaymentReference';
import CheckoutPaymentSpei from './checkouts/checkoutPaymentSpei/checkoutPaymentSpei';

const visa = "/images/026_Icon_Visa.svg";
const mc = "/images/025_icon_Mastercard.svg";
const titleLogo = "/images/035_Icon_Metodo.svg";
const banorte = "/images/banorte.png";
const speiLogo = "/images/spei-logo.png";
const sevenEleven = "/images/Seven_eleven_logo.svg";
const telecommm = "/images/020_Ahorro.svg";
const carnet = "/images/007_icon_carnet.svg";
const codiLogo = "/images/codiLogo.png";

class CheckoutMain extends Component {
  state = {
    msgError: null,
    isMethodSelected: false,
    paymentType: '',
  };

  componentDidMount() {
    checkoutInfo.orderInfo(this.props.params.token)
      .then(result => {
        if (result.msgError) {
          this.setState({
            msgError: result.msgError
          });
        } else {
          this.backPage = result?.webhook?.backPage || null;
          this.props.payOrderSaveActions({
            token: this.props.params.token,
            ...result
          });
        }
      })
      .catch((err) => {
        console.log(err);
      })
  }

  handleRegularPaymentSelect = (type = '') => {
    this.setState({
      isMethodSelected: (type)? true : false ,
      paymentType: type
    });
  };

  render() {
    const { t } = this.props;
    const {
      commerceDetails,
      distributorInfo,
      transactionInfo,
      payOrder
    } = this.props.payOrder;

    if (this.state.msgError) {
      return (
        <div className="checkoutMain">
          <ErrorComponent
            msgError={this.state.msgError}
          />
        </div>
      );
    }

    if (!commerceDetails && !transactionInfo) {
      return ;
    }

    let headerCommerce = null;
    if (commerceDetails) {
      headerCommerce = {
        backgroundColor: commerceDetails.commerceDesign && commerceDetails.commerceDesign.barColor
          ? commerceDetails.commerceDesign.barColor : '#4b88bb',
        containedPrimarycolor: commerceDetails.commerceDesign && commerceDetails.commerceDesign.primaryColor
          ? commerceDetails.commerceDesign.primaryColor : '#0171ae',
        containedSecondarycolor: commerceDetails.commerceDesign && commerceDetails.commerceDesign.secondaryColor
          ? commerceDetails.commerceDesign.secondaryColor : '#03a1d4',
        checkoutIconData: commerceDetails.commerceDesign && commerceDetails.commerceDesign.checkoutIconData ? commerceDetails.commerceDesign.checkoutIconData : '/images/008_icon_Exporta.svg',
        checkoutIconShop: commerceDetails.commerceDesign && commerceDetails.commerceDesign.checkoutIconShop ? commerceDetails.commerceDesign.checkoutIconShop : '/images/009_icon_Detalle.svg',
        distributorImgName: distributorInfo && distributorInfo.imgName ? distributorInfo.imgName : '/images/000Logo.svg',
        imgLogoProcess: distributorInfo && distributorInfo.imgLogoProcess ? distributorInfo.imgLogoProcess : '/images/010_logo_espiralapp.svg'
      };
    }

    return (
      <div className="checkoutMain">
        {this.backPage && (
          <div style={
            {
              'textAlign': 'right',
              'width': '100%',
              'maxWidth': '1024px'
            }
          }>
            <Button
              variant="text"
              color="error"
              href={this.backPage}>
              Salir
            </Button>
          </div>
        )}
        {payOrder?.linkDetails?.idEnvironment === 1 && (<Helmet>
            <script src="https://includestest.ccdc02.com/cardinalcruise/v1/songbird.js"></script>
          </Helmet>)}
        {payOrder?.linkDetails?.idEnvironment === 2 && (<Helmet>
            <script src="https://songbird.cardinalcommerce.com/edge/v1/songbird.js"></script>
          </Helmet>)}
        <div
          className="header-commerce"
          style={headerCommerce}>
          {(commerceDetails.commerceDesign
            && commerceDetails.commerceDesign.logo) ? (
              <div className="commerce-name">
                <img src={commerceDetails.commerceDesign.logo} alt="Logo" />
              </div>
            ) : (
              <div className="commerce-name">
                {commerceDetails.commerceName}
              </div>
            )}
        </div>
        {!this.state.isMethodSelected && (
          <div className="container">
            <Container maxWidth="lg">
              <div className="container-title">
                <div>
                  <img
                    className="container-title_logo"
                    src={titleLogo}
                    alt="cart_logo"
                  />
                </div>
                <div>
                  <h1 style={{color: '#4b88bb'}}>
                    <span>{t('select')} </span> {t('yourPayment')}
                  </h1>
                </div>
              </div>
              <div className="container-products">
                {transactionInfo.creditCardPaymentEnable
                  && transactionInfo.bank === 2
                  && (
                  <SelectorComponent
                    click={(event) => this.handleRegularPaymentSelect('v-mc-wOut-3d')}
                    logo={"/images/037_Icon_Debito.svg"}
                    title={t('regularPaymentTitle')}
                    description={t('regularPaymentDescription')}
                    styles={headerCommerce}
                    bank={mc}
                    bank2={visa}
                    bank3={carnet}
                  />
                )}
                {transactionInfo.creditCardPaymentEnable
                  && (
                    transactionInfo.bank === 1
                    || !transactionInfo.bank
                  )
                  && (
                  <SelectorComponent
                    click={(event) => this.handleRegularPaymentSelect('v-mc-w-3d-banorte')}
                    logo={"/images/037_Icon_Debito.svg"}
                    title={t('regularPaymentTitle')}
                    description={t('regularPaymentDescription')}
                    styles={headerCommerce}
                    bank={mc}
                    bank2={visa}
                    bank3={carnet}
                  />
                )}
                {transactionInfo.referencePaymentEnable && (
                  <SelectorComponent
                    click={(event) => this.handleRegularPaymentSelect('paymentReference')}
                    logo={"/images/003_icon_referenciado.svg"}
                    title={t('referencePaymentTitle')}
                    description={t('referencePaymentDescription')}
                    styles={headerCommerce}
                    bank={banorte}
                    bank2={sevenEleven}
                    bank3={telecommm}
                    plus={true}
                  />
                )}
                {(transactionInfo.enableSpei
                  && commerceDetails?.clabe?.stpAccountClabe?.[0]?.clabe?.clabe) && (
                  <SelectorComponent
                    click={(event) => this.handleRegularPaymentSelect('paymentSpei')}
                    logo={"/images/003_icon_referenciado.svg"}
                    title={t('speiPaymentTitle')}
                    description={t('speiPaymentDescription')}
                    styles={headerCommerce}
                    bank={speiLogo}
                    bank2={codiLogo}
                  />
                )}
                {/* {transactionInfo.codiPaymentEnable && (
                  <SelectorComponent
                    click={(event) => this.handleRegularPaymentSelect('paymentSpei')}
                    logo={"/images/003_icon_referenciado.svg"}
                    title={t('codiPaymentTitle')}
                    description={t('codiPaymentDescription')}
                    styles={headerCommerce}
                    bank={speiLogo}
                  />
                )} */}
              </div>
            </Container>
          </div>
        )}

        {(this.state.isMethodSelected) && (
          <Container maxWidth="lg">
            <nav className="nav">
              <div className="nav-link">
                <p className="backTo" onClick={() => this.handleRegularPaymentSelect(false)}>
                  <span>&lt; </span> {t('backToMenu')}
                </p>
              </div>
            </nav>
          </Container>
        )}

        {(transactionInfo.securityType3DSecure
          && this.state.isMethodSelected
          && this.state.paymentType === 'v-mc-wOut-3d') && (
          <Checkout3dSecureV2
            handleBack={() => this.handleRegularPaymentSelect()}
            styles={headerCommerce}/>
        )}
        {(transactionInfo.securityType3DSecure
          && this.state.isMethodSelected
          && this.state.paymentType === 'v-mc-w-3d-banorte') && (
          <Checkout3dSecureV2Banorte
            handleBack={() => this.handleRegularPaymentSelect()}
            styles={headerCommerce}/>
        )}
        {(!transactionInfo.securityType3DSecure
          && this.state.isMethodSelected
          && this.state.paymentType === 'v-mc-w-3d-banorte') && (
          <CheckoutWithOuth3dSecure
            handleBack={() => this.handleBack()}
            styles={headerCommerce}/>
        )}
        {( this.state.isMethodSelected
          && this.state.paymentType === 'paymentReference') && (
          <CheckoutPaymentReference
            distributorInfo={distributorInfo}
            handleBack={() => this.handleRegularPaymentSelect()}
            styles={headerCommerce}/>
        )}
        {( this.state.isMethodSelected
          && this.state.paymentType === 'paymentSpei') && (
          <CheckoutPaymentSpei
            distributorInfo={distributorInfo}
            handleBack={() => this.handleRegularPaymentSelect()}
            styles={headerCommerce}/>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    payOrder: state.payOrder
  };
}

const CheckoutMainTag = connect(mapStateToProps, { payOrderSaveActions })(withTranslation()(CheckoutMain))

const WrappedComponent = (props) => {
  const params = useParams();
  return (
    <CheckoutMainTag
      {...props}
      params={params}
    />
  );
};

export default WrappedComponent;
