import { Component } from "react";
import "./loadView.scss";

class LoadView extends Component {
  render() {
    return (
      <div className="load-view">
          <img src="/images/004LogoEspiral.svg" alt="" />
      </div>
    );
  }
}


export default LoadView;
