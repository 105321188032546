import React from "react";
import { useTranslation } from 'react-i18next';

import "./Errors.scss";

const Errors = (props) => {
  const { t } = useTranslation();
  return (
    <div className="container-msg-error">
      <div className="msg-error">
        {(props.msgError === 'order payed') && (
          <div>
            <div className="img-error">
              <img src="https://storage.googleapis.com/mailing-images-belugapay/carrito/017_rechazada.png" alt="Error"></img>
            </div>
            <span>
              {t('orderPayed')}
            </span>
          </div>
        )}
        {(props.msgError === 'jwt expired') && (
          <div>
            <div className="img-error">
              <img src="https://storage.googleapis.com/mailing-images-belugapay/carrito/017_rechazada.png" alt="Error"></img>
            </div>
            <span>
              {t('orderExpired')}
            </span>
          </div>
        )}
        {(props.msgError === 'Limites excedidos para transacciones sin 3Dsecure') && (
          <div>
            <div className="img-error">
              <img src="https://storage.googleapis.com/mailing-images-belugapay/carrito/017_rechazada.png" alt="Error"></img>
            </div>
            <span>
              {t('3dsecureLimit')}
            </span>
          </div>
        )}
        {(props.msgError === 'jwt malformed') && (
          <div>
            <div className="img-error">
              <img src="https://storage.googleapis.com/mailing-images-belugapay/carrito/017_rechazada.png" alt="Error"></img>
            </div>
            <span>
              {t('jwtMalformed')}
            </span>
          </div>
        )}
        {(props.msgError === 'invalid signature') && (
          <div>
            <div className="img-error">
              <img src="https://storage.googleapis.com/mailing-images-belugapay/carrito/017_rechazada.png" alt="Error"></img>
            </div>
            <span>
              {t('invalidSignature')}
            </span>
          </div>
        )}
        {(props.msgError === 'API-KEY no valida') && (
          <div>
            <div className="img-error">
              <img src="https://storage.googleapis.com/mailing-images-belugapay/carrito/017_rechazada.png" alt="Error"></img>
            </div>
            <span>
              {t('API-KEYNoValid')}
            </span>
          </div>
        )}
      </div>
    </div>
  )
}


export default Errors;