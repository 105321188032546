import React, { Component } from "react";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";

import Backdrop from '@mui/material/Backdrop';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

import CheckoutResponse from '../checkoutResponse/checkoutResponse';

import DataForm from "../../forms/dataFormV2/dataForm";
import DetailCard from "../../../purchDetailCard/purchaseDetailCard";
import ItemList from "../../../listItem/listItem";
import EndPayment from "../../../endPaymentCard/endPayment";

import {
  enrollmet as enrollmetRequest,
  authentication,
  sale,
  tokenCard
} from '../../../../utils/checkoutInfo';

import "./checkout3dSecure2.0.scss";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  padding: '40px'
};

class Checkout3dSecure extends Component {

  state = {
    transactionBody: {},
    lastStep: false,
    cardinalComplete: false,
    messageError: null,
    modalOpen: false,
    transactionResponse: null,
    paymentInformation: {
      paymentInstrument: null,
      instrumentIdentifier: null
    }
  }
  cardinalInit = false;

  constructor() {
    super();
    window.Cardinal.configure({
      logging: {
        level: "verbose",
      },
    });
  }

  InitCardinal(orderObject) {
    const context = this;
    const jwt = this.props.payOrder.transactionIdentifier.jwt;

    window.Cardinal.setup("init", {
      jwt: jwt,
      order: orderObject,
    });

    if(!this.cardinalInit) {
      this.cardinalInit = true;
      window.Cardinal.on("payments.setupComplete", () => {
        console.log('Init Authenticate');
        this.setState({
          cardinalComplete: true
        })
      });
      window.Cardinal.on("payments.validated", function (data, jwt) {
        console.log('Finish Authenticate');
        context.authentication({
          consumerAuthenticationInformation: {
            authenticationTransactionId: data.Payment.ProcessorTransactionId
          }
        });
      });
    }
  }

  AuthenticateCardinal(data) {
    window.Cardinal.continue(
      "cca",
      {
        AcsUrl: data.acsUrl,
        Payload: data.pareq,
      },
      {
        OrderDetails: {
          TransactionId: data.transactionId,
        },
      }
    );
  }

  prepareTokenBodyRequest() {
    const exp = this.state.transactionBody.exp.split('/');
    return {
      paymentInformation: {
        card: {
          expirationMonth: exp[0],
          expirationYear: `20${exp[1]}`,
          number: this.state.transactionBody.cardNumber
        }
      }
    };
  }

  prepareBodyRequest() {
    const exp = this.state.transactionBody.exp.split('/');
    const country = this.state.transactionBody.country.split('-');
    let state = ['', '', ''];
    let locality = ['', '', ''];
    if (country[1] === 'MX') {
      state = this.state.transactionBody.state.split('-');
      locality = this.state.transactionBody.locality.split('-');
    } else if (country[1] === 'US' || country[1] === 'CA') {
      state = this.state.transactionBody.state.split('-');
      locality[2] = this.state.transactionBody.locality;
    } else {
      state[1] = this.state.transactionBody.state;
      locality[2] = this.state.transactionBody.locality;
    }
    return {
      orderInformation: {
        amountDetails: {
          currency: 'MXN',
          totalAmount: this.props.payOrder.transactionInfo.price3dSecureMXN
        },
        lineItems: this.props.payOrder.payOrder.transaction.items.map(item => ({
          totalAmount: Number(item.quantity) * Number(item.price),
          quantity: item.quantity,
          unitPrice: item.price,
          productName: item.name,
          productDescription: item.description,
          giftCardCurrency: this.props.payOrder.payOrder.transaction.currency
        }))
      },
      billTo: {
        address1: this.state.transactionBody.address1,
        administrativeArea: state[1],
        country: country[1],
        locality: locality[2],
        firstName: this.state.transactionBody.name,
        lastName: this.state.transactionBody.lastName,
        phoneNumber: this.state.transactionBody.phoneNumber,
        email: this.state.transactionBody.email,
        postalCode: this.state.transactionBody.postalCode,
      },
      paymentInformation: {
        card: {
          expirationMonth: exp[0],
          expirationYear: `20${exp[1]}`,
          number: this.state.transactionBody.cardNumber
        },
        // paymentInstrument: {
        //   id: this.state.paymentInformation.paymentInstrument
        // }
      },
      consumerAuthenticationInformation: {
        reference: this.props.payOrder.transactionIdentifier.reference
      }
    }
  }

  prepareSaleBodyRequest() {
    const exp = this.state.transactionBody.exp.split('/');
    const country = this.state.transactionBody.country.split('-');
    let state = ['', '', ''];
    let locality = ['', '', ''];
    if (country[1] === 'MX') {
      state = this.state.transactionBody.state.split('-');
      locality = this.state.transactionBody.locality.split('-');
    } else if (country[1] === 'US' || country[1] === 'CA') {
      state = this.state.transactionBody.state.split('-');
      locality[2] = this.state.transactionBody.locality;
    } else {
      state[1] = this.state.transactionBody.state;
      locality[2] = this.state.transactionBody.locality;
    }
    return {
      paymentInformation: {
        card: {
          number: this.state.transactionBody.cardNumber,
          expirationMonth: exp[0],
          expirationYear: `20${exp[1]}`,
          securityCode: this.state.transactionBody.cvv
        },
        // instrumentIdentifier: {
        //   id: this.state.paymentInformation.instrumentIdentifier
        // },
        // paymentInstrument: {
        //   id: this.state.paymentInformation.paymentInstrument
        // }
      },
      cardHolder: {
        firstName: this.state.transactionBody.name,
        lastName: this.state.transactionBody.lastName,
        email: this.state.transactionBody.email,
        phone: this.state.transactionBody.phoneNumber,
      },
      address: {
        country: country[1],
        state: state[1],
        city: locality[2],
        zipCode: this.state.transactionBody.postalCode,
        street: this.state.transactionBody.address1
      },
      linkDetails: this.props.payOrder.linkDetails,
      metadata: this.props.payOrder.metadata,
      transaction: {
        items: this.props.payOrder.payOrder.transaction.items.map(item => ({
          totalAmount: Number(item.quantity) * Number(item.price),
          quantity: item.quantity,
          unitPrice: item.price,
          productName: item.name,
          productDescription: item.description,
          giftCardCurrency: this.props.payOrder.payOrder.transaction.currency
        })),
        total: this.props.payOrder.transactionInfo.price3dSecureMXN,
        currency: 'MXN',
      }
    }
  }

  async enrollmet() {
    this.setState({
      cardinalComplete: false
    });
    // const tokenCardResponse = await tokenCard(
    //   this.props.payOrder.commerceDetails.id,
    //   this.prepareTokenBodyRequest(),
    //   this.props.payOrder.token
    // );
    // if (!tokenCardResponse.idPaymentInstrument) {
    //   this.setState({
    //     cardinalComplete: true,
    //     messageError: 'Error al comprobar, intentar ingresar otro método de pago.'
    //   });
    //   return false;
    // }
    // this.state.paymentInformation.instrumentIdentifier = tokenCardResponse.idInstrumentIdentifier;
    // this.state.paymentInformation.paymentInstrument = tokenCardResponse.idPaymentInstrument;
    enrollmetRequest(
      this.props.payOrder.commerceDetails.id,
      {
        ...this.prepareBodyRequest(),
        clientReferenceInformation: {
          code: this.props.payOrder.transactionIdentifier.reference
        }
      },
      this.props.payOrder.token
    )
      .then(result => {
        console.log(result);
        if (result.status === 403) {
          this.setState({
            cardinalComplete: true,
            messageError: 'No autorizado'
          });
          return false;
        }
        const {
          consumerAuthenticationInformation,
          status
        } = result.payload;
        if (consumerAuthenticationInformation?.acsUrl && status === 'PENDING_AUTHENTICATION') {
          this.AuthenticateCardinal({
            transactionId: consumerAuthenticationInformation.authenticationTransactionId,
            acsUrl: consumerAuthenticationInformation.acsUrl,
            pareq: consumerAuthenticationInformation.pareq
          });
        } else if (status === 'AUTHENTICATION_SUCCESSFUL') {
          this.requestSale(result.payload);
        } else {
          this.setState({
            cardinalComplete: true,
            messageError: 'Error al comprobar, intentar ingresar otro método de pago.'
          });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          cardinalComplete: true,
          messageError: 'Error al comprobar, intentar ingresar otro método de pago.'
        })
      })
  }

  authentication(data) {
    const dataForm = this.prepareBodyRequest();
    authentication(
      this.props.payOrder.commerceDetails.id,
      {
        ...dataForm,
        clientReferenceInformation: {
          code: this.props.payOrder.transactionIdentifier.reference
        },
        consumerAuthenticationInformation: data.consumerAuthenticationInformation
      }, this.props.payOrder.token
    )
      .then((result) => {
        this.requestSale(result.payload);
      })
  }

  requestSale(data3Dsecure) {
    const data = this.prepareSaleBodyRequest();
    sale(
      this.props.payOrder.commerceDetails.id,
      {
        ...data,
        validateAuthentication: data3Dsecure
      }, this.props.payOrder.token
    )
      .then((response) => {
        this.setState({
          modalOpen: true,
          transactionResponse: response.payload,
          transactionError: typeof response?.messages?.[0] === 'string' ? response?.messages?.[0] : '',
          cardinalComplete: true
        });
      })
  }

  handleChangeInput(event) {
    const inputName = event.target.name;
    const value = event.target.value;
    if (inputName === 'cardNumber') {
      this.setState({
        transactionBody: {
          ...this.state.transactionBody,
          [inputName]: value
        },
        cardType: value[0]
      });
    } else if (inputName === 'country' && inputName === 'state' && inputName === 'locality') {
      this.setState({
        transactionBody: {
          ...this.state.transactionBody,
          [inputName]: value
        }
      });
    } else {
      this.setState({
        transactionBody: {
          ...this.state.transactionBody,
          [inputName]: value
        }
      });
    }
  }

  handleContinueBtn = () => {
    if (
      !this.state?.transactionBody?.address1
      || !this.state?.transactionBody?.cardNumber
      || !this.state?.transactionBody?.country
      || !this.state?.transactionBody?.cvv
      || !this.state?.transactionBody?.email
      || !this.state?.transactionBody?.exp
      || !this.state?.transactionBody?.lastName
      || !this.state?.transactionBody?.locality
      || !this.state?.transactionBody?.name
      || !this.state?.transactionBody?.phoneNumber
      || !this.state?.transactionBody?.postalCode
      || !this.state?.transactionBody?.state
    ) {
      alert('Faltan campos por llenar');
      return false;
    }
    this.InitCardinal({
      Consumer: {
        Account: {
          AccountNumber: this.state.transactionBody.cardNumber,
        },
      },
    });
    this.setState({
      lastStep: true
    });
  };

  handleModifyPayment = () => {
    this.setState({
      lastStep: false,
      cardinalComplete: false,
      messageError: null
    });
  }

  handleModal(status) {
    this.setState({
      modalOpen: status
    });
  };

  render() {
    const { t } = this.props;
    if (!this.props.payOrder?.payOrder?.transaction?.currency) {
      return ;
    }

    const {
      payOrder,
      transactionInfo
    } = this.props.payOrder;

    if (transactionInfo && !this.state.lastStep) {
      return (
        <div className="checkout-3d-v2">
          <Container maxWidth="lg">
            <div className="Checkout3dSecurev2">
              <div className="container-info-cardholder">
                <DataForm 
                  quantity={payOrder.transaction.items.length}
                  transactionBody={this.state.transactionBody}
                  // inputCorrect={this.state.inputCorrect}
                  // paymentInMonths={this.state.cardInfo}
                  amount={transactionInfo.price3dSecureMXN}
                  onChangeInput={(event) => this.handleChangeInput(event)}
                  // cardType={this.state.cardType}
                  styles={this.props.styles}
                />
              </div>
              <div className="container-info-sale">
                <DetailCard
                  titleImage={
                    this.props.styles.checkoutIconShop
                  }
                  title={t('yourPurchase')}
                  color="blue"
                  currency={payOrder.transaction.currency}
                  total={payOrder.transaction.total}
                  styles={this.props.styles}
                >
                  {payOrder.transaction.items.map((item, index) => {
                    return (
                      <ItemList
                        key={index}
                        name={item.name}
                        quantity={item.quantity}
                        description={item.description}
                        currency={payOrder.transaction.currency}
                        price={item.price}
                        styles={this.props.styles}
                      />
                    );
                  })}
                </DetailCard>
                <div className="butons">
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ backgroundColor: this.props.styles.containedPrimarycolor }}
                    onClick={this.props.handleBack}>{t('cancelBtn')}</Button>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ backgroundColor: this.props.styles.containedPrimarycolor }}
                    onClick={this.handleContinueBtn}>{t('continueBtn')}</Button>
                </div>
              </div>
            </div>
            <center><small>V2.C</small></center>
          </Container>
        </div>
      );
    } else if (transactionInfo && this.state.lastStep) {
      return (
        <div className="checkout-3d-v2">
          <Container maxWidth="lg">
            <div className="Checkout3dSecurev2">
              <EndPayment 
                lastFour={this.state.transactionBody.cardNumber.slice(-4)}
                handleMod={this.handleModifyPayment}
                // cardType={this.state.cardType}
                styles={this.props.styles}
              />
              <div className="container-info-sale">
                <DetailCard
                  titleImage={
                    this.props.styles.checkoutIconShop
                  }
                  title={t('yourPurchase')}
                  color="green"
                  currency={payOrder.transaction.currency}
                  total={payOrder.transaction.total}
                  styles={this.props.styles}
                >
                  {payOrder.transaction.items.map((item, index) => {
                    return (
                      <ItemList
                        key={index}
                        name={item.name}
                        quantity={item.quantity}
                        description={item.description}
                        currency={payOrder.transaction.currency}
                        price={item.price}
                        total={payOrder.transaction.total}
                        styles={this.props.styles}
                      />
                    );
                  })}
                </DetailCard>
                <div className="butons">
                  {this.state.cardinalComplete
                  && !this.state.messageError
                  && this.state.transactionResponse?.espiralData?.idTransactionStatus !== 1
                  && (
                    <Button
                      variant="contained"
                      color="primary"
                      className="large"
                      onClick={() => this.enrollmet()}
                      style={{ backgroundColor: this.props.styles.containedPrimarycolor }}>{t('pay')}</Button>
                  )}
                  {!this.state.cardinalComplete
                  && !this.state.transactionResponse && (
                    <CircularProgress />
                  )}
                  {this.state.cardinalComplete
                    && this.state.messageError
                    && (
                    <Stack sx={{ width: '100%' }} spacing={2}>
                      <Alert severity="error">{this.state.messageError}</Alert>
                    </Stack>
                  )}
                </div>
              </div>
            </div>
            <center><small>V2.C</small></center>
          </Container>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={this.state.modalOpen}
            onClose={() => this.handleModal(false)}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
            className="modal-container"
          >
            <Box sx={style}>
              <CheckoutResponse
                transactionResponse={{
                  reference: this.state?.transactionResponse?.bank?.clientReferenceInformation?.code,
                  date: this.state?.transactionResponse?.bank?.submitTimeUtc,
                  pan: this.state?.transactionResponse?.data?.transaction?.pan,
                  idTransactionStatus: (
                    this.state?.transactionResponse?.bank?.status === 'AUTHORIZED'
                  ) ? 1 : 0,
                  idTransaction: this.state?.transactionResponse?.espiralData?.id
                }}
                payOrder={this.props.payOrder.payOrder}
                distributorInfo={this.state.distributorInfo}
                transactionInfo={transactionInfo}
                errorTransaction={this.state?.transactionError}
                total={payOrder.transaction.total}
                styles={this.props.styles}
                cardType={this.state.cardType}/>
            </Box>
          </Modal>
        </div>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    payOrder: state.payOrder
  };
}

export default connect(mapStateToProps)(withTranslation()(Checkout3dSecure));
