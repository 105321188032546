import { useState, useEffect } from "react";

const CountdownTimer = ({ expirationTime }) => {
    const calculateRemainingTime = () => {
      if (expirationTime) {
        const currentTime = new Date().getTime();
        const expiresIn = new Date(expirationTime).getTime();
        const timeDifference = expiresIn - currentTime;
        
        const seconds = String(Math.floor((timeDifference / 1000) % 60)).padStart(2, '0');
        const minutes = String(Math.floor((timeDifference / 1000 / 60) %60)).padStart(2, '0');
        const hours = String(Math.floor(timeDifference / 1000 / 60 /60)).padStart(2, '0');

        if(timeDifference <= 0) {
          return { hours: '00', minutes: '00', seconds: '00' };
        }

        return { hours, minutes, seconds };
      }

      return { hours: '00', minutes: '00', seconds: '00' };
    };

    const [remainingTime, setRemainingTime] = useState(calculateRemainingTime());

    useEffect(() => {
        const interval = setInterval(() => {
            setRemainingTime(calculateRemainingTime());
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    return (
        <div>
        {expirationTime !== null ? (
            <div>
            <span>
                Tiempo restante: {remainingTime.hours}:{remainingTime.minutes}:{remainingTime.seconds}
            </span>
        </div>
        ) : (
            <span>
                Token invalido o tiempo de expiración no establecido
            </span>
        )}
        </div>
    );
};

export default CountdownTimer;