import React from "react";
import { formatCurrency } from '../../func/formatCurrency';
import "./listItem.scss";

const item = (props) => {
    return (
        <div className="item">
            <div>
                <div className="item-name">
                    <p>{props.name}</p>
                </div>
                <div className="item-info">
                    <p>
                        x {props.quantity} {props.description}
                    </p>
                </div>
            </div>
            <div className="item-currency">
                <div>
                    <h2 style={{ color: props.styles.containedPrimarycolor }}>{props.currency} $ {formatCurrency(Number(props.price))}</h2>
                </div>
            </div>
        </div>
    );
};

export default item;
