function orderInfo(token) {
  const url = `${global.config.URL.BASE}/payOrder/${token}`;
  return fetch(url)
    .then((response) => response.json())
    .then((data) =>{
      if (data.error) {
        return {
          msgError: data.error,
          loadingData: false
        };
      } else {
        return {
          transactionInfo: data.transactionInfo,
          commerceDetails: data.commerceDetail,
          commerceDesign: (data.commerceDetail.commerceDesign) ? data.commerceDetail.commerceDesign : null,
          distributorInfo: data.distributorInfo,
          transactionIdentifier: data.transactionIdentifier,
          payOrder: data.payOrder,
          webhook: data.webhook,
          metadata: data.metadata,
          loadingData: false
        }
      }
    })
    .catch(err => {
      console.log(err);
    });
}

module.exports = orderInfo;
