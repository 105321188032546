import { Component } from "react";
import { connect } from "react-redux";
import ReactToPrint from "react-to-print";
import Button from '@mui/material/Button';
import { withTranslation } from 'react-i18next';
import "./checkoutPaymentReference.scss";
import React from "react";

class ComponentToPrint extends Component {

  getExpiration() {
    const date = new Date();
    date.setDate(date.getDate() + 5);

    const dd = date.getDate();
    const mm = date.getMonth() + 1;
    const y = date.getFullYear();

    return `${dd} / ${(mm < 10 ? `0${mm}` : mm) } / ${y}`;
  }

  formatCurrency (number) {
    const numberSplit = Number(number).toFixed(2).toString().split('.');
    const formatNumber = (numberSplit && numberSplit[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','));
    return formatNumber + '.' + ((numberSplit && numberSplit[1]) ? numberSplit[1] : '00');
  }

  render() {
    const { t } = this.props;
    var Barcode = require('react-barcode');
    return (
      <div className="print-container">
        <div className="container-referencePay">
          <div className="logo">
            <span>
              {t('ProcessedBy')}
            </span>
            <img src={this.props.distributorInfo.imgLogoProcess} alt="Logo" />
          </div>
          {/* <div className="container-title-reference">
            <img className="icon-checkout" src="/images/009_icon_Detalle.svg" alt="" /> {t('detailsPurchase')}
          </div> */}
          <div className="table-data">
          <div className="title">
              {t('commerceName')}
            </div>
            <div className="data-reference">
              {this.props.commerceDetails.commerceName}
            </div>
            <div className="line"></div>
            <div className="title monto-weight">
              {t('service')}
            </div>
            <div className="data-reference monto-weight">
              Pago Referenciado Banorte
            </div>
            <div className="line"></div>
            <div className="title monto-weight">
              Facturador
            </div>
            <div className="data-reference monto-weight">
              {global.config.REFERENCE.SERVICE} / {global.config.REFERENCE.SERVICENAME}
            </div>
            <div className="line"></div>
            <div className="title monto-weight">
              {t('reference')}
            </div>
            <div className="data-reference monto-weight">
              {this.props.reference}
            </div>
            <div className="line"></div>
            <div className="title monto-weight">
              {t('amount')}
            </div>
            <div className="data-reference monto-weight">
              MXN $ {this.formatCurrency(this.props.amount)}
            </div>
            {/* <div className="line"></div> */}
            {/* <div className="title">
              {t('effectiveUntil')}
            </div>
            <div className="data-reference">
              {this.getExpiration()}
            </div> */}
          </div>
          <div className="purchase-details">
            {/* <div>
              {t('purchaseDetails')}
            </div> */}
            <div className="items-list">
              {this.props.items && this.props.items.map((value, index) => {
                return (
                  <span key={index}>
                    <div>
                      {value.name} <span className="quantity">x{value.quantity}</span> 
                    </div>
                    <div>
                      $ {this.formatCurrency(value.price)}
                    </div>
                    <div>
                      {value.description && (
                        <span>{value.description}</span>
                      )}
                    </div>
                  </span>
                )
              })}
            </div>
          </div>
          <div className="services-allow">
            <div>
              {t('storesAvailable')}
            </div>
            <div className="services-images">
              <img className="logo" src="/images/banorte.png" alt="" />
              <img className="logo" src="/images/011_seven.svg" alt="" />
              {/* <img className="logo" src="/images/012_wallmart.svg" alt="" /> */}
              {/* <img className="logo" src="/images/013_Sams.svg" alt="" /> */}
              {/* <img className="logo" src="/images/014_superama.svg" alt="" /> */}
              <img className="logo" src="/images/015_Aurrera.svg" alt="" />
              {/* <img className="logo" src="/images/016_Farmacias.svg" alt="" /> */}
              <img className="logo" src="/images/017_Telecomm.svg" alt="" />
              <img className="logo" src="/images/018_Delsol.svg" alt="" />
              <img className="logo" src="/images/019_Chedraui.svg" alt="" />
              <img className="logo" src="/images/020_Ahorro.svg" alt="" />
              <img className="logo" src="/images/021_Yastas.svg" alt="" />
              <img className="logo" src="/images/022_Woolworth.svg" alt="" />
            </div>
          </div>
          <div className="instructions-details">
            <div className="services-allow">
              <div>
                {t('instructions')}
              </div>
              <div className="instructions-steps">
                <ol>
                  <li>
                    {t('PresentCashier')}
                  </li>
                  <li>
                    {t('RequestBanorteReferencedPayment')}
                  </li>
                  <li>
                    Proporciona el Facturador "005142 / ESPIRALCASH"
                  </li>
                  <li>
                    {t('ProvideReference')}
                  </li>
                  <li>
                    {t('RequestProofPayment')}
                  </li>
                </ol>
              </div>
            </div>
          </div>
          {
            this.props.reference && (
              <div className="barcode">
                <Barcode ref={this.BarcodeRef} value={global.config.REFERENCE.SERVICE} />
              </div>
          )}
          {
            this.props.reference && (
              <div className="barcode">
                <Barcode ref={this.BarcodeRef} value={this.props.reference} />
              </div>
          )}
        </div>
      </div>
    );
  }
}

class checkoutPaymentReference extends Component {

  state = {
    reference: ''
  };

  componentDidMount() {
    this.getReference();
    console.log(this.props);
  }

  getReference() {

    const url = `${global.config.URL.BASE}/reference`;
    const header = new Headers();

    header.append('Content-Type', 'application/json');
    header.append('Authorization', `Bearer ${this.props.payOrder.transactionIdentifier.jwtOrder}`);

    const req = new Request(url, {
      method: 'POST',
      body: JSON.stringify({
        'idCommerce': this.props.payOrder.commerceDetails.id,
        'amount': this.props.payOrder.transactionInfo.price3dSecureMXN,
        'expirationDays': 6
      }),
      headers: header
    });

    fetch(req)
      .then((response) => response.json())
      .then((data) =>{
        this.setState({
          reference: data.payload.reference
        });
      })
      .catch(err => {
        console.log(err);
      });
  }


  render() {
    const { t } = this.props;
    return (
      <div className="checkoutPaymentReference">
        <ComponentToPrint
          ref={(el) => (this.componentRef = el)}
          reference={this.state.reference}
          amount={this.props.payOrder.transactionInfo.price3dSecureMXN}
          items={this.props.payOrder.payOrder.transaction.items}
          distributorInfo={this.props.distributorInfo}
          commerceDetails={this.props.payOrder.commerceDetails}
          t={t} />

        <div className="text-center">
          <ReactToPrint
            trigger={() => 
              <Button
                variant="contained"
                color="primary"
                className="large paymentbtn">{t('print')}</Button>}
            content={() => this.componentRef}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    payOrder: state.payOrder
  };
}

export default connect(mapStateToProps)(withTranslation()(checkoutPaymentReference));
