// We pass in a state, which is empty by default
// and an action which we will learn about in the 
// actions file
const payOrderReducer = (state = {}, action) => {
  // Clone state object
  let newState = Object.assign({}, state);
  // Look for type set in the actions file
  // these types should be as unique as possible
  switch (action.type) {
    case "PAY_ORDER_SAVE":
      newState = {
        ...action.payload
      };
      break;
    default:
      break;
  }

  // return the modified state
  return newState;
};

export default payOrderReducer;
