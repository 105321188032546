function isEmail(email) {
  return /^[\w-\.]+@([\w-]+\.)+[\w-]{2,10}$/.test(email);
}

function isCard(card) {
  return /^\d\d\d\d\d\d\d\d\d\d\d\d\d\d\d\d$/.test(card);
}

function isExp(card) {
  return /^\d\d\/\d\d$/.test(card);
}

function isCvv(card) {
  return /^\d\d\d$/.test(card);
}

module.exports = {
  isEmail,
  isCard,
  isExp,
  isCvv
}