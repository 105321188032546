import React from "react";
import "./purchaseDetailCard.scss";

import { formatCurrency } from '../../func/formatCurrency';
import Card from "../formCard/card";
import { withTranslation } from 'react-i18next';

const dataForm = (props) => {
  const { t } = props;
  return (
    <Card styles={props.styles}>
      <div className="purchaseDetailCard">
        <div className="process-By">
          <div>
            <p>{t('processedBy')}</p>
          </div>
          <div>
            <img src={props.styles.imgLogoProcess} alt="" />
          </div>
        </div>
        <div className="title">
          <div>
            <img
              className="title-image"
              src={props.titleImage}
              alt="cart_icon"
            />
          </div>
          <div>
            <h1 className="title-name" style={{ color: props.styles.containedPrimarycolor }}>{props.title}</h1>
          </div>
        </div>
        <hr className={props.color} style={{ border: `1px solid ${props.styles.containedSecondarycolor}` }} />
        <div className="list-container">
          {props.children}
        </div>
        <div className="total">
          <div>
              <p style={{ color: props.styles.containedPrimarycolor }}>Total</p>
          </div>
          <div>
          <h2 style={{ color: props.styles.containedPrimarycolor }}>{props.currency} $ {formatCurrency(Number(props.total))}</h2>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default withTranslation()(dataForm);
