import React from "react";
import { useTranslation } from 'react-i18next';
import Container from '@mui/material/Container';

import { useSelector } from "react-redux";

const Footer = () => {
  const serviceSelected = useSelector(state => state?.payOrder?.distributorInfo?.distributorName || '');

  const { t } = useTranslation();

  const today = new Date();
  const year = today.getFullYear();

  return (
    <Container maxWidth="lg" className="container-footer">
      <footer>
        <div className="container-brands">
          <span className="container-icon">
            <img className="brands" src='/images/025_Mastecard.svg' alt="" />
          </span>
          <span className="container-icon">
            <img className="brands" src='/images/024_Visa.svg' alt="" />
          </span>
          {/* <span className="container-icon">
            <img className="brands" src='/images/033_Banorte.svg' alt="" />
          </span> */}
          <span className="container-icon">
            <img className="brands" src='/images/034_PCI.svg' alt="" />
          </span>
          <span className="container-icon">
            <img className="brands" src='/images/035_BancoMexico.svg' alt="" />
          </span>
          <span className="container-icon securreTrust">
            <a href="https://sealserver.trustwave.com/cert.php?customerId=3d84ec08f1ab42b297618662bd424dbf&size=105x54&style=invert" target="_blank" rel="noopener noreferrer">
              <img id="trustwaveSealImage" src="https://sealserver.trustwave.com/seal_image.php?customerId=3d84ec08f1ab42b297618662bd424dbf&size=105x54&style=invert" border="0" style={{ cursor: 'pointer' }} alt="This site is protected by Trustwave's Trusted Commerce program" title="This site is protected by Trustwave's Trusted Commerce program" />
            </a>
          </span>
        </div>
        <p className="terms">
          &copy; {year} {serviceSelected}© {" "}
          <a href="https://dash.espiralapp.com/es/privacyPolicies" target="_blank" rel="noreferrer">
            {t('terms')} &{" "} {t('privacy')}
          </a>
        </p>
      </footer>
    </Container>
  )
}


export default Footer;