import { Component } from "react";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";

import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import "./checkoutWithOuth3dSecure.scss";

import CheckoutResponse from '../checkoutResponse/checkoutResponse';

import EndPayment from "../../../endPaymentCard/endPayment";
import DataForm from "../../../dataForm/dataForm";
import DetailCard from "../../../purchDetailCard/purchaseDetailCard";
import ItemList from "../../../listItem/listItem";
import LoadView from "../../../GeneralComponents/loadView/loadView";
import { isEmail, isCard, isExp, isCvv } from '../../../../func/validInput';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  padding: '40px'
};

class CheckoutWithOuth3dSecure extends Component {

  state = {
    payStatus: true,
    loading: false,
    cardType: '',
    inputCorrect: null,
    transactionInfo: {
      items: [],
      transaction: {
        total: 0
      },
      total: 0
    },
    lastStep: false,
    transactionBody: {
      email: ''
    },
    commerceDetails: {},
    modalOpen: false,
    payDisabled: false,
    transactionResponse: {},
    errorTransaction: ''
  }

  componentDidMount() {
    this.setState({
      transactionInfo: this.props.transactionInfo,
      commerceDetails: this.props.commerceDetails,
      distributorInfo: this.props.distributorInfo
    });
  }

  handleModifyPayment = () => {
    this.setState({ lastStep: false });
  }

  handleChangeInput = (event) => {
    if (event.target.name === 'cardNumber') {
      this.setState({
        transactionBody: {
          ...this.state.transactionBody,
          [event.target.name]: event.target.value
        },
        cardType: event.target.value[0]
      });
    } else {
      this.setState({
        transactionBody: {
          ...this.state.transactionBody,
          [event.target.name]: event.target.value
        }
      });
    }
  }

  handleContinueBtn = () => {
    const emailError = isEmail(this.state.transactionBody.email);
    const emailCardHolderName = (this.state.transactionBody.cardHolderName
      && this.state.transactionBody.cardHolderName !== '');
    const cardNumber = isCard(this.state.transactionBody.cardNumber);
    const expError = isExp(this.state.transactionBody.exp);
    const cvvError = isCvv(this.state.transactionBody.cvv);

    if (
      emailError
      && emailCardHolderName
      && cardNumber
      && expError
      && cvvError) {
        this.setState({
          inputCorrect: {
            email: emailError,
            cardHolderName: emailCardHolderName,
            cardNumber: cardNumber,
            exp: expError,
            cvv: cvvError
          },
          lastStep: true
        });
    } else {
      this.setState({
        inputCorrect: {
          email: emailError,
          cardHolderName: emailCardHolderName,
          cardNumber: cardNumber,
          exp: expError,
          cvv: cvvError
        },
        lastStep: false
      });
    }
  };

  handlePaymentBtn = () => {
    const transactionBody = {
      transaction: {
        cardNumber: this.state.transactionBody.cardNumber.replaceAll('-', ''),
        email: this.state.transactionBody.email,
        cvv: Number(this.state.transactionBody.cvv),
        exp: this.state.transactionBody.exp,
        cardHolderName: this.state.transactionBody.cardHolderName,
        currency: this.props.payOrder.payOrder.transaction.currency
      }
    };

    const url = `${global.config.URL.BASE}/cart/pay`;
    const header = new Headers();

    header.append('Authorization', `Bearer ${this.props.payOrder.transactionIdentifier.jwtOrder}`);
    header.append('Content-Type', 'application/json');

    const req = new Request(url, {
      method: 'POST',
      body: JSON.stringify(transactionBody),
      headers: header
    });

    this.setState({
      loading: true
    });

    fetch(req).then(res => res.json())
    .then(response => {
      if (response.body && response.body.message === 'Approved') {
        this.setState({
          modalOpen: true,
          payStatus: false,
          transactionResponse: response.body,
          errorTransaction: null
        });
      } else if (response.body && response.body.data && response.body.data.transaction) {
        this.setState({
          modalOpen: true,
          payStatus: true,
          transactionResponse: response.body,
          errorTransaction: response.error
        });
      } else {
        this.setState({
          modalOpen: true,
          payStatus: true,
          errorTransaction: 'Rejected',
          transactionResponse: {
            "codigo": 200,
            "mensaje": "Rechazada",
            "code": 200,
            "message": "Rejected",
            "data": {
              "transaction": {
                "transactionId": "0000",
                "authCode": null,
                "cardType": null,
                "autStatusResult": "R",
                "autResult": null,
                "cardBrand": null,
                "reference": null,
                "controlNumber": "",
                "issuingBank": null,
                "custReqDate": "",
                "custRespDate": "",
                "autReqDate": null,
                "autRespDate": null,
                "pan": "0000"
              }
            }
          }
        });
      }
      this.setState({
        loading: false
      });
    })
    .catch(err => {
      this.setState({
        loading: false
      });
      console.log(err);
    });
  }

  handleModal(status) {
    this.setState({
      modalOpen: status
    });
  };

  render() {
    const { t } = this.props;
    const transactionInfo = this.props.payOrder.payOrder.transaction;

    if (transactionInfo && !this.state.lastStep) {
      return (
        <Container maxWidth="lg">
          <div className="CheckoutWithOuth3dSecure">
            <DataForm 
                quantity={transactionInfo.items.length}
                handleBack={this.props.handleBack}
                transactionBody={this.state.transactionBody}
                inputCorrect={this.state.inputCorrect}
                onChangeInput={this.handleChangeInput}
                cardType={this.state.cardType}
                styles={this.props.styles}
              />
            <div className="container-info-sale">
              <DetailCard
                titleImage={
                  this.props.styles.checkoutIconShop
                }
                title={t('yourPurchase')}
                color="blue"
                currency={transactionInfo.currency}
                total={transactionInfo.total}
                styles={this.props.styles}
              >
                {transactionInfo.items.map((item, index) => {
                  return (
                    <ItemList
                      key={index}
                      name={item.name}
                      quantity={item.quantity}
                      description={item.description}
                      currency={transactionInfo.currency}
                      price={item.price}
                      total={transactionInfo.total}
                      styles={this.props.styles}
                    />
                  );
                })}
              </DetailCard>
              <div className="butons">
                <Button
                  variant="contained"
                  color="primary"
                  style={{ backgroundColor: this.props.styles.containedPrimarycolor }}
                  onClick={this.props.handleBack}>{t('cancelBtn')}</Button>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ backgroundColor: this.props.styles.containedPrimarycolor }}
                  onClick={this.handleContinueBtn}>{t('continueBtn')}</Button>
              </div>
            </div>
          </div>
        </Container>
      );
    } else if (transactionInfo && this.state.lastStep) {
      return (
        <Container maxWidth="lg">
          <div className="CheckoutWithOuth3dSecure">
            <EndPayment 
              lastFour={this.state.transactionBody.cardNumber.slice(-4)}
              handleMod={this.handleModifyPayment}
              cardType={this.state.cardType}
              styles={this.props.styles}
            />
            <div className="container-info-sale">
              <DetailCard
                titleImage={
                  this.props.styles.checkoutIconShop
                }
                title={t('yourPurchase')}
                color="green"
                currency={transactionInfo.currency}
                total={transactionInfo.total}
                styles={this.props.styles}
              >
                {transactionInfo.items.map((item, index) => {
                  return (
                      <ItemList
                        key={index}
                        name={item.name}
                        quantity={item.quantity}
                        description={item.description}
                        currency={transactionInfo.currency}
                        price={item.price}
                        total={transactionInfo.total}
                        styles={this.props.styles}
                      />
                  );
                })}
              </DetailCard>
              <div className="butons">
                {(this.state.payStatus) && (
                  <Button
                    variant="contained"
                    color="primary"
                    className="large"
                    onClick={this.handlePaymentBtn}
                    style={{ backgroundColor: this.props.styles.containedPrimarycolor }}>{t('pay')}</Button>
                )}
              </div>
            </div>
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              open={this.state.modalOpen}
              onClose={() => this.handleModal(false)}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
              className="modal-container"
            >
              <Box sx={style}>
                <CheckoutResponse
                  transactionResponse={{
                    reference: this.state?.transactionResponse?.data?.transaction?.reference,
                    date: this.state?.transactionResponse?.data?.transaction?.custRespDate,
                    pan: this.state?.transactionResponse?.data?.transaction?.pan,
                    idTransactionStatus: (
                      this.state?.transactionResponse?.data?.transaction?.autStatusResult === 'A'
                    ) ? 1 : 0
                  }}
                  payOrder={this.props.payOrder.payOrder}
                  distributorInfo={this.state.distributorInfo}
                  transactionInfo={transactionInfo}
                  errorTransaction={this.state.errorTransaction}
                  total={transactionInfo.total}
                  styles={this.props.styles}
                  cardType={this.state.cardType}/>
              </Box>
            </Modal>
            {this.state.loading && (
              <LoadView/>
            )}
          </div>
        </Container>
      );
    } else {
      return false;
    }
  }
}

function mapStateToProps(state) {
  return {
    payOrder: state.payOrder
  };
}

export default connect(mapStateToProps)(withTranslation()(CheckoutWithOuth3dSecure));