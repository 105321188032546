import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationEN from './locales/en/translation.json';
import translationSpa from './locales/spa/translation.json';

// the translations
const resources = {
  spa: {
    translation: translationSpa
  },
  en: {
    translation: translationEN
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "spa",
    fallbackLng: "en",

    interpolation: {
      escapeValue: false
    }
  });


export default i18n;