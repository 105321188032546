import React, { Component } from "react";

import PropTypes from 'prop-types';
import { IMaskInput } from 'react-imask';
import Card from "../../../formCard/card";
import TextField from '@mui/material/TextField';
import { withTranslation } from 'react-i18next';

import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';

import './dataForm.scss';

const TextMaskCard = React.forwardRef(function TextMaskCard(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask='0000 0000 0000 0000 000'
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
      unmask={true}
    />
  );
});

TextMaskCard.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const TextMaskExp = React.forwardRef(function TextMaskExp(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask='00 / 00'
      inputRef={ref}
      onAccept={(value) => onChange({
        target: { name: props.name, value: value.replace(/\s/g, '') }
      })}
      overwrite
    />
  );
});

TextMaskExp.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const TextMaskCvv = React.forwardRef(function TextMaskCvv(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask='000'
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

TextMaskCvv.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

class DataForm extends Component {

  state = {
    countries: [],
    states: [],
    cities: []
  }

  componentDidMount() {
    Promise.all([
      this.getCountries(),
      this.getStates('159')
    ]).then(values => {
      this.props.onChangeInput({
        target: {
          name: 'country',
          value: '159-MX-Mexico'
        }
      });
      this.setState({
        countries: values[0].payload,
        states: values[1].payload
      });
    });
  }

  getCountries() {
    const url = `${global.config.PRIVATE.BASE}/api/countries`;
    const header = new Headers();

    header.append('Content-Type', 'application/json');

    const req = new Request(url, {
      method: 'GET',
      headers: header
    });

    this.setState({
      loading: true
    });

    return fetch(req).then(res => res.json())
      .then(response => response)
      .catch(err => {
        this.setState({
          loading: false
        });
        console.log(err);
      });
  }

  getStates(idCountry = '') {
    const url = `${global.config.PRIVATE.BASE}/api/states?idCountry=${idCountry}`;
    const header = new Headers();

    header.append('Content-Type', 'application/json');

    const req = new Request(url, {
      method: 'GET',
      headers: header
    });

    this.setState({
      loading: true
    });

    return fetch(req).then(res => res.json())
      .then(response => response)
      .catch(err => {
        this.setState({
          loading: false
        });
        console.log(err);
      });
  }

  getCities(id) {
    const url = `${global.config.PRIVATE.BASE}/api/city/${id}`;
    const header = new Headers();

    header.append('Content-Type', 'application/json');

    const req = new Request(url, {
      method: 'GET',
      headers: header
    });

    this.setState({
      loading: true
    });

    return fetch(req).then(res => res.json())
      .then(response => response)
      .catch(err => {
        this.setState({
          loading: false
        });
        console.log(err);
      });
  }

  render() {
    const { t } = this.props;

    return (
      <Card styles={this.props.styles}>
        <div className="header">
          <p>
          <span>{this.props.quantity} {t('articles')}</span>{t('inCart')}
          </p>
        </div>
        <div className="title">
          <div>
            <img
              className="title-image"
              src={this.props.styles.checkoutIconData}
              alt="car_icon"
            />
          </div>
          <div>
            <h1
              className="title-name"
              style={{
                color: this.props.styles.containedPrimarycolor
              }}>{t('formTitle')}</h1>
          </div>
        </div>
        <hr className="blue-bar"
          style={{
            border: `1px solid ${this.props.styles.containedSecondarycolor}`
          }}/>
        <form id="sendTransaction" name="cardInfo">
          <div className="fields">
            <TextField
              id="name"
              name="name"
              label={t('name')}
              type="name"
              error={!this.props.inputCorrect || this.props.inputCorrect.name ? false : true }
              value={this.props.transactionBody && this.props.transactionBody.name ? this.props.transactionBody.name : ''}
              onChange={this.props.onChangeInput}
            />
            <TextField
              id="lastName"
              name="lastName"
              className="ml-2"
              label={t('lastName')}
              type="lastName"
              error={!this.props.inputCorrect || this.props.inputCorrect.lastName ? false : true }
              value={this.props?.transactionBody?.lastName ? this.props.transactionBody.lastName : ''}
              onChange={this.props.onChangeInput}
            />
          </div>
          <div className="fields">
            <FormControl fullWidth>
              <InputLabel variant="standard" htmlFor="uncontrolled-native">
                {t('country')}
              </InputLabel>
              <NativeSelect
                id="country"
                name="country"
                error={!this.props.inputCorrect || this.props.inputCorrect.country ? false : true }
                value={this.props?.transactionBody?.country ? this.props.transactionBody.country : '159'}
                onChange={(event => {
                  this.props.onChangeInput(event);
                  const country = event.target.value.split('-')[0];
                  console.log(event.target.value);
                  if (country === '159' || country === '235' || country === '38') {
                    this.getStates(country)
                      .then(states => (
                        this.setState({
                          states: states.payload
                        })
                      ))
                  }
                })}
              >
                <option value=''></option>
                {this.state.countries.map((object, i) => {
                  return <option
                    key={i}
                    value={`${object.id}-${object.code}-${object.name}`}
                  >{object.name}</option>;
                })}
              </NativeSelect>
            </FormControl>
            {(
              this.props?.transactionBody?.country === '159-MX-Mexico' || 
              this.props?.transactionBody?.country === '235-US-United States of America' || 
              this.props?.transactionBody?.country === '38-CA-Canada'
            ) && (
              <FormControl fullWidth className="ml-2">
                <InputLabel variant="standard" htmlFor="uncontrolled-native">
                  {t('state')}
                </InputLabel>
                <NativeSelect
                  id="state"
                  name="state"
                  error={!this.props.inputCorrect || this.props.inputCorrect.state ? false : true }
                  value={this.props.transactionBody && this.props.transactionBody.state ? this.props.transactionBody.state : ''}
                  onChange={async (event) => {
                    this.props.onChangeInput(event);
                    const response = await this.getCities(event.target.value.split('-')[0]);
                    this.setState({
                      cities: response.payload
                    });
                  }}
                >
                  <option value=''></option>
                  {this.state.states.map((object, i) => {
                    return <option
                      key={i}
                      value={`${object.id}-${object.iso}-${object.name}-${object.abrev}`}
                    >{object.name}</option>;
                  })}
                </NativeSelect>
              </FormControl>
            )}
            {(
              this.props?.transactionBody?.country !== '159-MX-Mexico' &&
              this.props?.transactionBody?.country !== '235-US-United States of America' &&
              this.props?.transactionBody?.country !== '38-CA-Canada'
            ) && (
              <TextField
                id="state"
                name="state"
                className="ml-2"
                label={t('state')}
                type="state"
                error={!this.props.inputCorrect || this.props.inputCorrect.state ? false : true }
                value={this.props?.transactionBody?.state ? this.props.transactionBody.state : ''}
                onChange={this.props.onChangeInput}
              />
            )}
          </div>
          <div className="fields">
            {this.props?.transactionBody?.country === '159-MX-Mexico' && (
              <FormControl fullWidth>
                <InputLabel variant="standard" htmlFor="uncontrolled-native">
                  {t('locality')}
                </InputLabel>
                <NativeSelect
                  id="locality"
                  name="locality"
                  error={!this.props.inputCorrect || this.props.inputCorrect.locality ? false : true }
                  value={this.props.transactionBody && this.props.transactionBody.locality ? this.props.transactionBody.locality : ''}
                  onChange={this.props.onChangeInput}
                >
                  <option value=''></option>
                  {this.state.cities.map((object, i) => {
                    return <option
                      key={i}
                      value={`${object.id}-${object.idState}-${object.name}`}
                    >{object.name}</option>;
                  })}
                </NativeSelect>
              </FormControl>
            )}
            {this.props?.transactionBody?.country !== '159-MX-Mexico' && (
              <TextField
                id="locality"
                name="locality"
                label={t('locality')}
                type="locality"
                error={!this.props.inputCorrect || this.props.inputCorrect.locality ? false : true }
                value={this.props?.transactionBody?.locality ? this.props.transactionBody.locality : ''}
                onChange={this.props.onChangeInput}
              />
            )}
            <TextField
              id="postalCode"
              name="postalCode"
              className="ml-2"
              label={t('postalCode')}
              type="postalCode"
              error={!this.props.inputCorrect || this.props.inputCorrect.postalCode ? false : true }
              value={this.props?.transactionBody?.postalCode ? this.props.transactionBody.postalCode : ''}
              onChange={this.props.onChangeInput}
            />
          </div>
          <TextField
            id="address1"
            name="address1"
            label={t('address1')}
            type="address1"
            error={!this.props.inputCorrect || this.props.inputCorrect.address1 ? false : true }
            value={this.props?.transactionBody?.address1 ? this.props.transactionBody.address1 : ''}
            onChange={this.props.onChangeInput}
          />
          <div className="fields">
            <TextField
              id="email"
              name="email"
              label={t('emailLabel')}
              type="email"
              error={!this.props.inputCorrect || this.props.inputCorrect.email ? false : true }
              value={this.props.transactionBody && this.props.transactionBody.email ? this.props.transactionBody.email : ''}
              onChange={this.props.onChangeInput}
            />
            <TextField
              id="phoneNumber"
              name="phoneNumber"
              className="ml-2"
              label={t('phoneNumber')}
              type="phoneNumber"
              error={!this.props.inputCorrect || this.props.inputCorrect.phoneNumber ? false : true }
              value={this.props?.transactionBody?.phoneNumber ? this.props.transactionBody.phoneNumber : ''}
              onChange={this.props.onChangeInput}
            />
          </div>
          <hr/> 
          <div className="cardLogos">
            <TextField
              id="cardNumber"
              name="cardNumber"
              label={t('cardLabel')}
              type="text"
              error={!this.props.inputCorrect || this.props.inputCorrect.cardNumber ? false : true}
              value={this.props.transactionBody && this.props.transactionBody.cardNumber ? this.props.transactionBody.cardNumber : ''}
              onChange={this.props.onChangeInput}
              InputProps={{
                inputComponent: TextMaskCard,
              }}
              />
            <div className="formLogos">
              {(this.props.cardType === '4') && (
                <img className="visa" src="https://storage.googleapis.com/mailing-images-belugapay/carrito/002_visa.svg" alt="visa" />
              )}
              {(this.props.cardType === '5') && (
                <img className="master" src="https://storage.googleapis.com/mailing-images-belugapay/carrito/003_masterard.svg" alt="mc" />
              )}
            </div>
          </div>
          <div className="fields">
            <TextField
              id="exp"
              name="exp"
              label={t('dateLabel')}
              type="exp"
              error={!this.props.inputCorrect || this.props.inputCorrect.exp ? false : true}
              value={this.props.transactionBody && this.props.transactionBody.exp ? this.props.transactionBody.exp : ''}
              onChange={this.props.onChangeInput}
              InputProps={{
                inputComponent: TextMaskExp,
              }}
              />
            <TextField
              id="cvv"
              name="cvv"
              className="ml-2"
              label={t('cvvLabel')}
              type="cvv"
              error={!this.props.inputCorrect || this.props.inputCorrect.cvv ? false : true}
              value={this.props.transactionBody && this.props.transactionBody.cvv ? this.props.transactionBody.cvv : ''}
              onChange={this.props.onChangeInput}
              InputProps={{
                inputComponent: TextMaskCvv,
              }}
              />
          </div>
          { this.props.msi
          && this.props.amount >= 300
          && this.props.paymentInMonths
          && this.props.paymentInMonths.nature === 'CREDITO'
          && this.props.paymentInMonths.processor === 'PROSA'
          && this.props.paymentInMonths.bankingInstitutionName !== 'BBVA'
          && this.props.paymentInMonths.bankingInstitutionName !== 'CITIBANAMEX' && (
            <div>
              <FormControl fullWidth>
                <InputLabel variant="standard" htmlFor="uncontrolled-native">
                  {t('monthsWithoutInterest')}
                </InputLabel>
                <NativeSelect
                  id="months"
                  name="months"
                  onChange={this.props.onChangeInput}
                >
                  <option value=''></option>
                  {this.props.amount >= 300 && (<option value='03'>3 MSI</option>)}
                  {this.props.amount >= 600 && (<option value='06'>6 MSI</option>)}
                  {this.props.amount >= 900 && (<option value='09'>9 MSI</option>)}
                  {this.props.amount >= 1200 && (<option value='12'>12 MSI</option>)}
                  {this.props.amount >= 1800 && (<option value='18'>18 MSI</option>)}
                </NativeSelect>
              </FormControl>
            </div>
          )}
        </form>
      </Card>
    );
  }
}

export default withTranslation()(DataForm);
