import React from "react";
import './card.scss';

const card = (props) => {
  return (
    <div
      className="cardContainer"
      style={{ border: `1px solid ${props.styles && props.styles.containedPrimarycolor ? props.styles.containedPrimarycolor : '#009de0'}` }}>
      {props.children}
    </div>
  );
};

export default card;
