import React from "react";
import "./endPayment.scss";

import Card from "../smallCard/card";
import { withTranslation } from 'react-i18next';

function EndPayment (props) {
  const { t } = props;
  return (
    <Card styles={props.styles}>
      <div className="title">
        <div>
          <img
            className="title-image"
            src={props.styles.checkoutIconData}
            alt="car_icon"
          />
        </div>
        <div>
          <h1
            className="title-name"
            style={{
              color: props.styles.containedPrimarycolor
            }}>{t('toFinishPayment')}</h1>
        </div>
      </div>
      <hr className="green"
        style={{
          border: `1px solid ${props.styles.containedSecondarycolor}`
        }}/>
      <div className="payment">
        <div className="payment-logo">  
          {(props.cardType === '4') && (
            <img className="visa" src="https://storage.googleapis.com/mailing-images-belugapay/carrito/002_visa.svg" alt="visa" />
          )}
          {(props.cardType === '5') && (
            <img className="master" src="https://storage.googleapis.com/mailing-images-belugapay/carrito/003_masterard.svg" alt="mc" />
          )}
        </div>
        <div className="payment-description">
          <p>{t('lastFour')} {props.lastFour}</p>
        </div>
        <div className="payment-modify" onClick={props.handleMod}>
          <p>{t('modify')}</p>
        </div>
      </div>
    </Card>
  );
};

export default withTranslation()(EndPayment);
