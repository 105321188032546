import React from "react";
import { useTranslation } from 'react-i18next';
import Container from '@mui/material/Container';

import { useSelector } from "react-redux";
import CountdownTimer from "../../../utils/CountdownTimer";
import { decodeToken } from 'react-jwt';

const Header = () => {

  const logoMain = useSelector(state => state?.payOrder?.distributorInfo?.imgName || '');
  const serviceSelected = useSelector(state => state?.payOrder?.distributorInfo?.distributorName || '');
  const jwtToken = useSelector(state => state?.payOrder?.transactionIdentifier?.jwtOrder || '');

  let decodedToken = decodeToken(jwtToken);
  let expirationTime = decodedToken?.exp;
  let expirationDate = new Date(expirationTime * 1000);

  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <Container maxWidth="lg">
      <nav className="nav">
        <div className="nav-link">
          {/* {(serviceSelected) && (
            <p className="backTo" onClick={handleBack}>
              <span>&lt; </span> {t('backToMenu')}
            </p>
          )} */}
          <span>
            {(serviceSelected === 'v-mc-wOut-3d') && (
              <span className="service-selected">
                <img src="/images/004_icon_debito.svg" alt="reference" /> {t('paymentcard')}
              </span>
            )}
            {(serviceSelected === 'paymentReference') && (
              <span className="service-selected">
                <img src="/images/003_icon_referenciado.svg" alt="reference" /> {t('paymentReference')}
              </span>
            )}
            {(serviceSelected === 'paymentAMEX') && (
              <span className="service-selected">
                <img src="/images/004_icon_debito.svg" alt="reference" /> {t('amexPaymentTitle')}
              </span>
            )}
          </span>
        </div>
        <div className="nav-logo">
          <div className="language">
            <p onClick={() => changeLanguage('spa')}>Esp</p>
            <p onClick={() => changeLanguage('en')}>Eng</p>
          </div>
          <img className="logo" src={logoMain} alt="" />
        </div>
      </nav>
      <div className="timer-container">
        <div className="x"></div>
        <div className="timer">
          {expirationDate == 'Invalid Date' ? '' :  <CountdownTimer expirationTime={expirationDate} />}
        </div>
      </div>
    </Container>
  )
}


export default Header;